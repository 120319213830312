#mapBar {
  // margin-top: 20px;
  display: flex;
  height: 65px;
  background-color: #e3f5e6;
  justify-content: center;
  align-items: center;

  .item {
    font-family: "Eudoxus Sans";
    margin: 10px;
    font-size: 17px;
    cursor: pointer;
    a {
      text-decoration: none;
      font-family: "Eudoxus Sans";
      // margin: 10px;
      font-size: 17px;
      cursor: pointer;
      color: black;
    }
  }
}

.marker_label {
  position: relative;
  top: 35px;
  font-family: Eudoxus sans;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.quick_selectors {
  p {
    font-family: Eudoxus sans;
    font-weight: bold;
    color: #1f443a;
    text-transform: capitalize;
    margin-left: 30px;
  }

  .quick_selectors_checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  hr {
    opacity: 0.3;
    margin: 30px;
  }
}

.layersButtonContainer {
  // position: relative;
  display: flex;
  background-color: black;

  .layersButton {
    position: absolute;
    display: flex;
    top: 50%;
    left: 20%;
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    background-color: black; //#1F443A
    color: #ffff;
    padding: 30px 15px;
    font-family: "Eudoxus sans";
    border: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1;
  }
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: -1;
}

/* Optionally, you can define a class for the animation */
.blinking {
  animation: pulsate 2s 1 ease-in-out;
  animation-delay: 2s;
}

.prometheusAIModal {
  width: 100%;
  // padding: 3px;
  box-sizing: border-box;

  .headingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      margin: 0;
      font-family: "Eudoxus Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      color: #1e232e;
    }
  }

  .buttonContainer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .buttondiv {
      width: 32%;
      height: 70px;
      border-radius: 8px;
      background: #fff;
      border: 1px solid #E2E2E2;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      h1 {
        margin: 0;
        color: #0E0F0D;
        font-family: 'Eudoxus Sans';
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
      }

      p {
        margin: 0;
        color: #6C6E6C;
        font-family: 'Eudoxus Sans';
        font-size: 10px;
        font-weight: 500;
        line-height: 12.6px;
        text-align: left;
      }
    }

    .buttonActivediv {
      width: 32%;
      height: 70px;
      border-radius: 8px;
      background: #1F443A;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      h1 {
        margin: 0;
        color: #fff;
        font-family: 'Eudoxus Sans';
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
      }

      p {
        margin: 0;
        color: #EDF0EF;
        font-family: 'Eudoxus Sans';
        font-size: 10px;
        font-weight: 500;
        line-height: 12.6px;
        text-align: left;
      }
    }

  }

  .analysisContainer {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .analysis {
      width: 32%;
      height: 262px;
      border-radius: 8px;
      border: 1px solid #E2E2E2;
      padding: 16px;
      box-sizing: border-box;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        margin: 0;
        margin-top: 20px;
        color: #424542;
        font-family: 'Eudoxus Sans';
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        // background: red;
        height: 60px;
      }

      .textContainer {
        display: flex;
        align-items: center;
        margin-top: 16px;
        gap: 16px;

        h3 {
          margin: 0;
          color: #0E0F0D;
          font-family: 'Eudoxus Sans';
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
        }
      }
    }
  }

  .para {
    margin: 24px 0;
    margin-top: 24px;
    font-family: "Eudoxus Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #787b82;
  }
}