.right {
  width: 375px;
  background-color: #fff;
  height: 65px;
  position: absolute;
  right: 0;
  top: 65px;
  box-sizing: border-box;
  overflow: hidden;

  .searchHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    gap: 10px;

    &__para {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #0c2721;
      margin: 0;
      padding: 0;
    }
  }

  .no_substation {
    // height: 50px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 10px 0px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .top {
    padding: 20px;

    .result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;

      &__left {
        display: flex;
        align-items: center;
        gap: 10px;

        &__heading {
          margin: 0;
          font-size: 24px;
          color: #0c2721;
          font-weight: bold;
        }

        &__backButton {
          background-color: #1f443a;
          width: 45px;
          height: 28px;
          color: #fff;
          font-size: 15px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          cursor: pointer;
        }

        &__badge {
          background-color: #1f443a;
          width: 28px;
          padding: 0px 5px;
          height: 28px;
          color: #fff;
          font-size: 15px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
        }
      }

      &__right {
        height: 31px;
        width: 31px;
      }
    }
  }

  .mainStamford {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-right: 10px;
    }
  }

  .stamford {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__heading {
      margin: 0;
      font-size: 16px;
      color: #0c2721;
      font-weight: bold;
    }

    &__button {
      outline: none;
      border: 0;
      border-radius: 50px;
      background: #e6f9ee;
      color: #27ae60;
      font-size: 17px;
      font-weight: bold;
      padding: 8px 15px;
    }

    &__para {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      color: #434746;
    }

    &__price {
      margin: 0;
      font-size: 14px;
      color: #0c2721;
    }
  }

  .active {
    background: #e3f5e6;

    .stamford {
      &__button {
        background: #1f443a;
        color: #e3f5e6;
      }
    }
  }

  .generation {
    margin-top: 20px;

    &__heading {
      margin: 0;
      color: #434746;
      font-size: 12px;
      font-weight: 500;
      font-family: "Eudoxus Sans";
    }

    &__container {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      margin-top: 5px;

      &__mw {
        margin: 0;
        font-size: 16px;
        color: #0c2721;
        font-weight: bold;
        margin-right: 5px;
      }

      &__value {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .location {
    display: flex;
    align-items: center;
    gap: 30px;
    // justify-content: space-between;
    padding: 0 15px;
    margin-top: 10px;
    background: #e3f5e6;
    border-radius: 8px;
    border: 1.5px solid #14d92b;

    &__para {
      font-size: 14px;
      font-weight: bold;
      color: #1f443a;
      width: 192px;
    }
  }

  .prometheus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 10px;
    background: #14d92b;
    border-radius: 8px;
    border: 1.5px solid #14d92b;
    cursor: pointer;

    &__para {
      font-size: 14px;
      font-weight: bold;
      color: #1f443a;
      width: 140px;
    }
  }

  .risk {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__score {
      font-size: 15px;
      font-weight: 600;
      color: #1f443a;
      margin: 5px 0;
    }

    &__para {
      font-size: 14px;
      font-weight: 600;
      color: #ff3f40;
      margin: 2px;

      span {
        color: #b9bbbd;
      }
    }

    &__heading {
      font-size: 30px;
      font-weight: bold;
      color: #1f443a;
    }
  }

  &__divider {
    margin-top: 15px;
  }
}

.open {
  .right {
    height: calc(100vh - 65px);
    overflow-y: scroll;
  }
}

.save_project {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-top: 20px;
  background: #e3f5e6;
  border-radius: 8px;
  border: 1.5px solid #14d92b;
  cursor: pointer;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #1f443a;
    width: 192px;
    text-align: center;
    margin: 0px;
    padding: 10px;
  }
}

.planningCard {
  padding: 5px;
  width: 320px;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;

  .planningCard__para {
    margin: 0px;
    color: black;
    padding: 5px;
    font-family: "Eudoxus sans";
    font-weight: bold;
    margin-bottom: 5px;
  }

  .planning {
    margin: 0px;
    padding: 0px;

    gap: 10px;

    p {
      font-family: "Eudoxus sans";
      color: black;
      font-size: 12px;
      margin: 0px;
    }
  }

  .planningText {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-family: "Eudoxus sans";
      margin: 0px;
      text-align: right;
    }
  }

  .planningBar {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
}

.progressSection {
  width: 100%;
  max-width: 150px;
  // border-radius: 5px;
  background: white;
  border-left: 1px solid #1f443a;
  // padding: 1rem 2rem;
  margin: 0;

  .taskProgress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin: 9px;
  }

  .taskProgress p {
    color: black;
  }

  span {
    color: #eee;
  }

  .progress {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    // border-radius: 10px;
    height: 15px;
  }

  .progress::-webkit-progress-bar {
    background-color: white;
  }

  // .progress::-webkit-progress-bar,
  // .progress::-webkit-progress-value {
  //     // border-radius: 10px;
  // }

  // .progress::-moz-progress-bar {
  //     // border-radius: 10px;
  // }

  .progress1::-webkit-progress-value {
    background: #25d739;
  }

  .progress2::-webkit-progress-value {
    background: #77c980;
  }

  .progress3::-webkit-progress-value {
    background: #d8f4ce;
  }

  .progress4::-webkit-progress-value {
    background: #8b4242;
  }
}

.substationCard {
  cursor: auto;
}

.substationCard:hover {
  background-color: #edecec6b;
  cursor: pointer;
}

.planningCardz {
  margin-top: 5px;
  width: 348px;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
  font-family: Eudoxus sans;

  &__heading {
    margin: 0px;
    color: #0C2721;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 5px;

    &__sub {
      margin: 0;
      color: #434746;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      display: flex;
      gap: 10px;
    }

    &__sub1 {
      margin: 0;
      color: #6C6E6C;
      font-size: 11px;
      line-height: 17px;
      font-weight: 500;
      display: flex;
      gap: 10px;
    }

    &__sub2 {
      margin: 0;
      color: #f23506;
      ;
      font-size: 11px;
      line-height: 17px;
      font-weight: 500;
    }
  }

  &__coventry {
    width: 268px;
    padding: 16px;
    border-radius: 8px;
    background: #F5F6F5;

    &__para {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #434746;

      span {
        font-weight: bold;
        color: #0C2721;
      }
    }
  }

  &__para {
    margin-top: 15px;
    color: #434746;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__alert {
    width: 268px;
    padding: 20px 16px;
    margin-top: 5px;
    border-radius: 8px;
    background: #ECFEEE;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    &__heading {
      color: #434746;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      margin-top: 3px;
    }

    &__para {
      color: #434746;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  &__imageContainer {
    img {
      width: 95%;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
    }

    h3 {
      margin: 0;
      margin-top: 20px;
      color: #0E0F0D;
      font-family: 'Eudoxus Sans';
      font-size: 16px;
      font-weight: 700;
      line-height: 20.16px;
      text-align: left;
    }
  }

  &__roadContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &__ppp {
      width: 50%;
      margin-top: 15px;

      &__pone {
        margin: 0;
        color: #6C6E6C;
        font-family: 'Eudoxus Sans';
        font-size: 10px;
        font-weight: 500;
        line-height: 12.6px;
        text-align: left;
      }

      &__ponee {
        margin: 0;
        color: #16A34A;
        font-family: 'Eudoxus Sans';
        font-size: 10px;
        font-weight: 500;
        line-height: 12.6px;
        text-align: left;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      &__ptwo {
        margin: 0;
        color: #0E0F0D;
        font-family: 'Eudoxus Sans';
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
        text-align: left;
        width: 90%;
        word-wrap: break-word;
      }

      &__ptwoe {
        margin: 0;
        color: #6C6E6C;
        font-family: 'Eudoxus Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 12.6px;
        text-align: left;

      }
    }
  }

  &__danger {
    width: 268px;
    padding: 20px 16px;
    margin-top: 5px;
    border-radius: 8px;
    background: #FFF6F5;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    &__heading {
      color: #434746;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      margin-top: 3px;
    }

    &__para {
      color: #434746;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }

    ul {
      margin-block-start: 0 !important;
      margin-block-end: 0 !important;
      padding-inline-start: 20px !important;

      li {
        color: #434746;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;

        span {
          font-weight: 700;
        }
      }
    }
  }

  &__route {
    width: 340px;
    // padding: 20px 16px;
    // padding-top: 0;
    margin-top: 5px;
    border-radius: 8px;
    // background: gainsboro;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    box-sizing: border-box;

    &__heading {
      color: #434746;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      margin-top: 3px;
    }

    &__para {
      color: #1D1D1D;
      /* Body default/medium */
      // font-family: Geist;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: -0.28px;
      margin: 0;
    }

    &__para1 {
      color: #6D6D6D;
      /* Body default/medium */
      // font-family: Geist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: -0.28px;
      margin: 0;
      margin: 8px 0;

      span {
        color: #1D1D1D;
        font-weight: 600;
      }
    }

    &__card {
      width: 300px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      padding: 12px 11px;
      gap: 15px;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 10px;
      // background: #F8F8F8;

      &__dot {
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 100px;
        background: red;
      }

      &__headingContainer {
        width: 178px;
        overflow: hidden;

        h3 {
          color: #1D1D1D;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.28px;
          margin: 0;
        }

        p {
          color: #464646;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin: 0;
        }
      }

      &__value {
        display: flex;
        width: 48px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        text-align: center;

        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }

    &__cards {
      width: 250px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      padding: 10px 10px;
      gap: 10px;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 10px;
      // background: #F8F8F8;

      &__dot {
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 100px;
        background: red;
      }

      &__headingContainer {
        width: 138px;
        overflow: hidden;

        h3 {
          color: #1D1D1D;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.28px;
          margin: 0;
        }

        p {
          color: #464646;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin: 0;
        }
      }

      &__value {
        display: flex;
        width: 48px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        text-align: center;

        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }

    &__costContainer {
      h3 {
        color: #1D1D1D;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.28px;
        margin-top: 15px;
        margin-bottom: 8px;
      }

      &__finalCost {
        display: flex;
        height: 40px;
        width: 100%;
        padding: 8px 12px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        // background: #F8F8F8;
        border: 1px solid #E5E5E5;
        color: #1D1D1D;
      }
    }

    &__totalCost {
      h4 {
        color: #6D6D6D;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.28px;
        margin-top: 25px;

        span {
          color: #1D1D1D;
          font-weight: 600;
        }
      }
    }

    ul {
      margin-block-start: 0 !important;
      margin-block-end: 0 !important;
      padding-inline-start: 20px !important;

      li {
        color: #434746;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;

        span {
          font-weight: 700;
        }
      }
    }
  }

  &__card {
    position: relative;
    left: 25px;
    top: 5px;
    margin-bottom: 5px;
    width: 250px;
    height: 174px;
    border-radius: 8px;
    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    overflow: hidden;

    &__container {
      height: 34px;
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      background: #fff;
      position: relative;

      &__heading {
        color: #0C2721;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        // text-align: center;
        margin: 0;
      }
    }

    img {
      width: 100%;
      height: calc(100% - 29px);
      margin-top: -5px;
      object-fit: cover;
      // z-index: 5;
    }

    &__counter {
      position: absolute;
      right: 10px;
      bottom: 15px;
      width: 36;
      height: 36;
      padding: 8px 9px;
      border-radius: 100px;
      box-sizing: border-box;
      background: #DE322C;
      // opacity: 0.4;

      &__heading {
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        text-align: center;
        margin: 0;
      }
    }
  }

  .planning {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    padding: 0px;

    gap: 10px;

    p {
      font-family: Eudoxus sans;
      color: black;
      font-size: 12px;
      margin: 0px;
    }
  }

  .planningText {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-family: Eudoxus sans;
      margin: 0px;
      text-align: right;
    }
  }

  .planningBar {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
}

input.valid {
  border-color: #28a745;
  padding-right: 30px;
  background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2-0-tdMgUcj4LMrtAfUkfQfYhAXgBqn2OjjYgjVUJoMQOy99BjIbzqEYyd0x32xpBDEY&usqp=CAU');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right center;
}

.styledRouteTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  text-align: left;
  border: 2px solid #c9c9c9;
  background-color: #ddd;
}

.styledRouteTable th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.styledRouteTable th,
td {
  // padding: 12px 12px;
  border: 1px solid #ddd;
}

.styledRouteTable thead th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.styledRouteTable tbody tr td {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
}

.styledTable {
  width: 100%;
  border-collapse: collapse;
  // margin: 25px 0;
  font-size: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.styledTable th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.styledTable thead th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.styledTable tbody tr td {
  border: 1px solid #ddd;
}

// .styledTable tbody tr:last-of-type {
//   border-bottom: 2px solid #333;
// }