.shareContainer {
    width: 540px;
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 50px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .para {
        margin: 0;
        margin-top: 3px;
        font-family: "Eudoxus Sans";
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #787B82;

        span {
            color: #1E232E;
        }
    }

    .share {
        margin-top: 30px;
        font-family: "Eudoxus Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #787b82;
    }

    .profile {
        display: flex;
        align-items: center;
        gap: 10px;

        h3 {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.20000000298023224px;
            color: #1e232e;
        }

        p {
            margin: 0;
            margin-top: 7px;
            font-family: "Eudoxus Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.20000000298023224px;
            color: #787b82;
        }
    }

    .selectContainer {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px
    }
}
