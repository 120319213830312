.prometheusAIModal {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .dottedCircle {
        width: 100%;
        margin-top: 20px;
        height: 500px;
        border-radius: 16px;
        border: 2px dotted #0AED24;
        padding: 20px;
        box-sizing: border-box;

        &__b {
            margin-top: -42px;
        }
    }

    .buttonContainer {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .buttondiv {
            width: 355px;
            height: 60px;
            border-radius: 8px;
            background: #fff;
            border: 1px solid #E2E2E2;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;

            h1 {
                margin: 0;
                color: #0E0F0D;
                font-family: 'Eudoxus Sans';
                font-size: 14px;
                font-weight: 600;
                line-height: 17.64px;
                text-align: left;
            }

            p {
                margin: 0;
                color: #6C6E6C;
                font-family: 'Eudoxus Sans';
                font-size: 10px;
                font-weight: 500;
                line-height: 12.6px;
                text-align: left;
            }
        }

        .buttonActivediv {
            width: 280px;
            height: 70px;
            border-radius: 8px;
            background: #1F443A;
            padding: 24px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            h1 {
                margin: 0;
                color: #fff;
                font-family: 'Eudoxus Sans';
                font-size: 14px;
                font-weight: 500;
                line-height: 17.64px;
                text-align: left;
            }

            p {
                margin: 0;
                color: #EDF0EF;
                font-family: 'Eudoxus Sans';
                font-size: 10px;
                font-weight: 500;
                line-height: 12.6px;
                text-align: left;
            }
        }

    }

    .secondaryAcquisitions {
        width: 100%;
        // height: 230px;
        max-height: 500px;
        overflow: auto;
        margin-top: 20px;
        border-radius: 16px;
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #E2E2E2;

        .buttondiv {
            width: 280px;
            height: 60px;
            border-radius: 8px;
            background: #fff;
            border: 1px solid #E2E2E2;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            h1 {
                margin: 0;
                color: #0E0F0D;
                font-family: 'Eudoxus Sans';
                font-size: 14px;
                font-weight: 500;
                line-height: 17.64px;
                text-align: left;
            }
        }
    }

    .accordianContainer {
        margin-top: 30px;
        display: flex;
        // align-items: center;
        justify-content: space-between;

        &__bb {
            width: 355px;
            height: 350px;
            // background: red;
            overflow-x: hidden;
            overflow-y: auto;

            .planningCardz {
                margin-top: 5px;
                width: 100%;
                border-radius: 5px;
                margin-top: 5px;
                position: relative;
                font-family: Eudoxus sans;

                &__heading {
                    margin: 0px;
                    color: #0c2721;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: bold;
                    margin-bottom: 5px;

                    &__sub {
                        margin: 0;
                        color: #434746;
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 500;
                        display: flex;
                        gap: 10px;
                    }

                    &__sub1 {
                        margin: 0;
                        color: #6C6E6C;
                        font-size: 11px;
                        line-height: 17px;
                        font-weight: 500;
                        display: flex;
                        gap: 10px;
                    }

                    &__sub2 {
                        margin: 0;
                        color: #f23506;
                        ;
                        font-size: 11px;
                        line-height: 17px;
                        font-weight: 500;
                    }
                }

                &__coventry {
                    width: 268px;
                    padding: 16px;
                    border-radius: 8px;
                    background: #f5f6f5;

                    &__para {
                        margin: 0;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #434746;

                        span {
                            font-weight: bold;
                            color: #0c2721;
                        }
                    }
                }

                &__para {
                    margin-top: 15px;
                    color: #434746;
                    font-weight: 500;
                    line-height: 17px;
                    text-align: center;
                    margin-bottom: 5px;
                }

                &__alert {
                    width: 268px;
                    padding: 20px 16px;
                    margin-top: 5px;
                    border-radius: 8px;
                    background: #ecfeee;
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;

                    &__heading {
                        color: #434746;
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 700;
                        margin-top: 3px;
                    }

                    &__para {
                        color: #434746;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                    }
                }

                &__imageContainer {
                    img {
                        width: 95%;
                        height: 200px;
                        object-fit: cover;
                        border-radius: 8px;
                    }

                    h3 {
                        margin: 0;
                        margin-top: 20px;
                        color: #0E0F0D;
                        font-family: 'Eudoxus Sans';
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 20.16px;
                        text-align: left;
                    }
                }

                &__roadContainer {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;

                    &__ppp {
                        width: 50%;
                        margin-top: 15px;

                        &__pone {
                            margin: 0;
                            color: #6C6E6C;
                            font-family: 'Eudoxus Sans';
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 12.6px;
                            text-align: left;
                        }

                        &__ponee {
                            margin: 0;
                            color: #16A34A;
                            font-family: 'Eudoxus Sans';
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 12.6px;
                            text-align: left;
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }

                        &__ptwo {
                            margin: 0;
                            color: #0E0F0D;
                            font-family: 'Eudoxus Sans';
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 17.64px;
                            text-align: left;
                        }

                        &__ptwoe {
                            margin: 0;
                            color: #6C6E6C;
                            font-family: 'Eudoxus Sans';
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 12.6px;
                            text-align: left;

                        }
                    }
                }

                &__danger {
                    width: 268px;
                    padding: 20px 16px;
                    margin-top: 5px;
                    border-radius: 8px;
                    background: gainsboro;
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;

                    &__heading {
                        color: #434746;
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 700;
                        margin-top: 3px;
                    }

                    &__para {
                        color: #434746;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                    }

                    ul {
                        margin-block-start: 0 !important;
                        margin-block-end: 0 !important;
                        padding-inline-start: 20px !important;

                        li {
                            color: #434746;
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;

                            span {
                                font-weight: 700;
                            }
                        }
                    }
                }

                &__card {
                    position: relative;
                    left: 25px;
                    top: 5px;
                    margin-bottom: 5px;
                    width: 250px;
                    height: 174px;
                    border-radius: 8px;
                    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    overflow: hidden;

                    &__container {
                        height: 34px;
                        width: 100%;
                        padding: 8px;
                        box-sizing: border-box;
                        background: #fff;
                        position: relative;

                        &__heading {
                            color: #0c2721;
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: 500;
                            // text-align: center;
                            margin: 0;
                        }
                    }

                    img {
                        width: 100%;
                        height: calc(100% - 29px);
                        margin-top: -5px;
                        object-fit: cover;
                        // z-index: 5;
                    }

                    &__counter {
                        position: absolute;
                        right: 10px;
                        bottom: 15px;
                        width: 36;
                        height: 36;
                        padding: 8px 9px;
                        border-radius: 100px;
                        box-sizing: border-box;
                        background: #de322c;
                        // opacity: 0.4;

                        &__heading {
                            color: #fff;
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: 500;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }

                .planning {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 0px;
                    padding: 0px;

                    gap: 10px;

                    p {
                        font-family: Eudoxus sans;
                        color: black;
                        font-size: 12px;
                        margin: 0px;
                    }
                }

                .planningText {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    p {
                        font-family: Eudoxus sans;
                        margin: 0px;
                        text-align: right;
                    }
                }

                .planningBar {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                }
            }
        }
    }

    .analysisContainer {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .analysis {
            width: 280px;
            height: 262px;
            border-radius: 8px;
            border: 1px solid #E2E2E2;
            padding: 16px;
            box-sizing: border-box;

            .iconContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            p {
                margin: 0;
                margin-top: 20px;
                color: #424542;
                font-family: 'Eudoxus Sans';
                font-size: 11px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                // background: red;
                height: 60px;
            }

            .textContainer {
                display: flex;
                align-items: center;
                margin-top: 16px;
                gap: 16px;

                h3 {
                    margin: 0;
                    color: #0E0F0D;
                    font-family: 'Eudoxus Sans';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                }
            }
        }
    }
}