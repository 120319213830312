.filterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .viewContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
}