.layers_buttons_Container {
    display: flex;
    justify-content: center;

    .layers_buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        button {
            font-size: 16px;
            padding: 10px 20px;
            font-family: "Eudoxus sans";
            border: none;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.6s ease;
        }
    }

    .selectedButton {
        background-color: #a4e7ac;
        color: black;
        font-weight: bold;
    }

    .unselectedButton {
        background-color: #f0f0f0;
        color: #333;
    }

    button:hover {
        font-weight: 300;
    }

    button:focus {
        outline: none;
    }
}
