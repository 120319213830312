.tabContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    margin-top: 14px;
    margin-bottom: 20px;

    &__collection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;
        width: 700px;
    }

    &__tab {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}