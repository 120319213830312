.feedbackContainer {
    // margin-left: 80px;
    padding: 24px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    width: calc(100% - 80px);
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: calc(100vh - 65px);

    .projectMapping {
        font-family: 'Eudoxus Sans';
        color: '##0E0F0D';
        font-size: 34px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
    }

    .para {
        margin: 0;
        margin-top: 24px;
        font-family: "Eudoxus Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #424542;

        span {
            font-family: 'Eudoxus Sans';
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: #1F443A;
            cursor: pointer;
        }
    }

    .inputFileContainer {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        gap: 5px;

        .inputFile {
            width: 650px;
            height: 85px;
            // padding: 33px 185px 33px 157px;
            gap: 0px;
            border-radius: 12px;
            border: 1px dashed #000;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            h5 {
                font-family: 'Eudoxus Sans';
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #0E0F0D;

                span {
                    font-family: 'Eudoxus Sans';
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #1F443A;
                    cursor: pointer;
                }
            }
        }

        .inputFile1 {
            width: 650px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            p {
                margin: 0;
                font-family: 'Eudoxus Sans';
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: #6C6E6C
            }
        }

        .showFile {
            margin-top: 10px;
            width: 650px;
            height: 75px;
            background: #EDF0EF;
            border-radius: 12px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            &__icon {
                display: flex;
                align-items: center;
                gap: 15px;

            }

            p {
                margin: 0;
                font-family: 'Eudoxus Sans';
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: #6C6E6C
            }
        }

        .analysisData {
            width: 537px;
            // background: red;

            h3 {
                font-family: 'Eudoxus Sans';
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                color: #0E0F0D;
            }

            .projectInFile {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                    margin-bottom: 10px;
                    font-family: 'Eudoxus Sans';
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #424542;
                }
            }

        }
    }

    .recommendationButtons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    .buttonMainContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
    }

    .userCollectionContainer {
        width: 100%;
        padding-top: 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        // justify-content: space-between;
        gap: 10px;
        margin-bottom: 50px;
    }

    .userCollection,
    .userCreateCollection {
        background-color: #EDF0EF;
        width: 300px;
        height: 100px;
        padding: 10px;
        border-radius: 12px;
        overflow: hidden;
        color: #1E232E;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 2px solid #1E232E;
        cursor: pointer;
        // box-sizing: border-box;

        img {
            height: 120px;
            width: 120px;
            object-fit: cover;
        }

        .userProjectContainer {
            p {
                font-family: "Eudoxus Sans";
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.20000000298023224px;
                text-align: left;
                margin: 0;
                // margin-bottom: 8px;
                margin-top: 40px;
            }

            .iconContainer {
                cursor: pointer;
            }

        }
    }

    .userCreateCollection {
        background-color: #fff;
        width: 300px;
        height: 100px;
        padding: 10px;
        border-radius: 12px;
        overflow: hidden;
        color: #1E232E;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        border: 2px dashed #E2E2E2;
        cursor: pointer;
    }

    .loadingText {
        height: 88%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-family: 'Eudoxus Sans';
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            color: #0E0F0D;
        }
    }
}