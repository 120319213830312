.inputContainer {
    border: 1px solid #DCDFE5;
    border-radius: 8px;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 15px;

    .t_input {
        margin-left: 10px;
        outline: none;
        border: 0px;
        // width: 454px;
        font-size: 14px;
        font-weight: 500
    }
}