.manageContainer {
    width: 500px;
    padding: 20px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .para {
        margin: 0;
        margin-top: 3px;
        margin-bottom: 30px;
        font-family: "Eudoxus Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #787b82;
    }
    .collectionContainer{
        margin: 20px 0px;
        min-height: 270px;
        // overflow-y: scroll;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-family: "Eudoxus Sans";
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #787b82;
        }
    }
    .buttonContainer1 {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-family: "Eudoxus Sans";
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #1e232e;
        }

        &__inner {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .line {
        background: #dcdfe5;
        height: 1px;
        width: 100%;
        margin-bottom: 10px;
    }
}
