.cardContainer {
    width: 100%;
    box-shadow: rgba(31, 68, 58, 0.08) 0px 2px 4px 0px;
    border-radius: 16px;
    padding: 16px 24px;
    box-sizing: border-box;
    overflow: hidden;

    .dateContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 14px;
            color: #787B82;
        }
    }

    .heading {
        font-family: 'Eudoxus Sans';
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #1E232E;
    }

    .assetContainer {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            margin: 0;
            font-family: 'Eudoxus Sans';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #1E232E;
        }

        .dot {
            width: 6px;
            height: 6px;
            border-radius: 50px;
            background: #1E232E;
        }

    }

    .buttonContainer {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__inner {
            display: flex;
            align-items: center;
            gap: 8px;
        }

    }
}