.main_container {
    width: calc(100% - 80px);
    padding: 24px;
  
    .heading_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .back_button_container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
      }
  
      .share_project_container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  
    .substation_name_container {
      margin-top: 8px;
      padding: 24px 8px;
  
      h1 {
        color: #1e232e;
  
        font-family: "Eudoxus Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: 0;
        margin-top: 8px;
      }
  
      p {
        color: #1e232e;
        font-family: "Eudoxus Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        margin: 0;
      }
  
      h2 {
        width: 650px;
        color: #787b82;
        font-family: "Eudoxus Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
      }
    }
  
    .substation_info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100%;
      gap: 16px;
  
      h1 {
        color: #1e232e;
  
        font-family: "Eudoxus Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
  
      .substation_info_container {
        width: 27%;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        height: 200px;
        max-height: 200px;
      }
  
      .site_voltage_container {
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
        width: 10%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px;
  
        .volt_btn_div {
          padding: 0px;
        }
  
        .volt__inner_div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
  
          p {
            padding: 0;
            margin: 0;
            color: #787b82;
            font-family: "Eudoxus Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
  
          h1 {
            color: #1e232e;
            font-family: "Eudoxus Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
  
      .demand_headroom_container {
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
        width: 27%;
        padding: 16px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
  
        .demand_inner_div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
  
          p {
            padding: 0;
            margin: 0;
            color: #787b82;
            font-family: "Eudoxus Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
  
          h1 {
            color: #1e232e;
            font-family: "Eudoxus Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  
    .substation_info_div {
      display: flex;
      flex-direction: column;
      gap: 17px;
  
      h1 {
        color: #1e232e;
        font-family: "Eudoxus Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }
  
      p {
        color: #787b82;
        font-family: "Eudoxus Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }
    }
  
    .last_main_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      // height: 100%;
      gap: 16px;
      margin-top: 8px;
  
      .score_container {
        width: 26.5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        margin-top: 16px;
        margin-right: 30px;
        .score_card_div {
          border-radius: 16px;
          background: #fff;
          box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
          width: 100%;
          height: 80px;
          flex-shrink: 0;
          padding: 16px;
          height: 110px;
  
          .progress_div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
          }
  
          h1 {
            color: #1e232e;
  
            font-family: "Eudoxus Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
  
          .label {
            color: #1e232e;
            text-align: right;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
  
          progress {
            border-radius: 99px;
            width: 70%;
            height: 10px;
          }
  
          progress::-webkit-progress-bar {
            background-color: #f3faf8;
            border-radius: 99px;
          }
  
          progress::-webkit-progress-value {
            background-color: #0aed24;
            border-radius: 7px;
            font-size: 200px;
          }
        }
        .danger_progress_bar {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
  
          progress::-webkit-progress-bar {
            background-color: #f3faf8;
            border-radius: 99px;
          }
  
          progress::-webkit-progress-value {
            background-color: red;
            border-radius: 7px;
            font-size: 200px;
          }
        }
        .planning__section {
          border-radius: 16px;
          background: #fff;
          box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
          display: inline-flex;
          padding: 24px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          width: 100%;
          height: 260px;
        }
      }
  
      .map__container {
        width: 39%;
        padding: 16px;
        border-radius: 16px;
        // width: 750px;
      }
  
      .search_criteria_container {
        width: 27%;
        height: 546px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 68, 58, 0.08);
        padding: 32px 0px 32px 24px;
        margin-top: 16px;
  
        .search_info_div {
          display: flex;
          flex-direction: column;
          gap: 32px;
          margin-top: 39px;
        }
        h1 {
          color: #1e232e;
  
          font-family: "Eudoxus Sans";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
  
        h2 {
          color: #1e232e;
          font-family: "Eudoxus Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
  
        p {
          color: #787b82;
          font-family: "Eudoxus Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  