.deleteContainer {
    padding: 20px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }
    .para {
        font-family: "Eudoxus Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #1e232e;
        margin-top: 20px;
    }

    .buttonContainer {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}
