.projectContainer {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 65px);

    .projects {
        padding: 24px 24px 0px 24px;
        overflow-y: auto;

        &__heading {
            color: #1E232E;
            font-family: 'Eudoxus Sans';
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 30px;
        }

    }

    .shareButtonContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        // margin-bottom: 20px;
    }
}

.pagination {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDF0EF;
    border-top: 1px solid #CCD4D2;

}