.land,
.layers {
  text-decoration: none;
  position: relative;
  font-weight: 700;
  font-family: "Eudoxus Sans";
  color: #151616;
  text-align: left;
}

.layers {
  align-self: stretch;
  font-size: 22px;
  letter-spacing: -0.01em;
  line-height: 28px;
  margin: 0;
}

.land {
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  min-width: 29px;
}

.landWrapper {
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 20px;
  cursor: pointer;
}

.infrastructure {
  text-decoration: none;
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Eudoxus Sans";
  color: #556561;
  font-weight: 700;
  text-align: left;
}

.frameParent,
.infrastructureWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.infrastructureWrapper {
  /* width: 79px; */
  cursor: pointer;
  flex-direction: column;
  padding: 8px 20px;
  box-sizing: border-box;
}

.frameParent {
  align-self: stretch;
  border-radius: 6px;
  background-color: #f6f8f7;
  overflow: hidden;
  flex-direction: row;
  padding: 2px;
  gap: 18px;
  height: 33px;
}

.england {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Eudoxus Sans";
  color: #151616;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.chevronDownIcon {
  height: 16px;
  width: 16px;
  position: relative;
  min-height: 16px;
}

.content,
.englandParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.englandParent {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #afb2b2;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 13px 10px 15px;
  gap: 20px;
}

.content {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1px 0 0;
  gap: 8px;
  top: 0;
  z-index: 99;
  position: sticky;
}

.separator {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid #f2f5f4;
  box-sizing: border-box;
}

.search {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  height: 40px;
  font-family: "Eudoxus Sans";
  font-weight: 500;
  font-size: 12px;
  color: #afb2b2;
}

.uncheckSquare {
  margin: 0;
  height: 16px;
  width: 16px;
  position: relative;
}

.showActiveLayers {
  flex: 1;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Eudoxus Sans";
  color: #556561;
  text-align: left;
}

.activeLayersLabel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 1px;
}

.activeLayersFilter {
  cursor: pointer;
  border: 1px solid #f2f5f4;
  padding: 6px 15px 5px 7px;
  background-color: #fff;
  width: 152px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.clearAll {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Eudoxus Sans";
  color: #556561;
  text-align: left;
  display: inline-block;
  min-width: 46px;
}

.clearButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 1px;
}

.clearFilter,
.filters {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.clearFilter {
  cursor: pointer;
  border: 1px solid #f2f5f4;
  padding: 6px 11px 5px;
  background-color: #fff;
  width: 70px;
  border-radius: 8px;
  box-sizing: border-box;
  justify-content: flex-start;
}

.filters {
  align-self: stretch;
  justify-content: space-between;
  padding: 0 1px 0 0;
  gap: 20px;
}

.areaSelection {
  align-self: stretch;
  height: 24px;
  font-family: "Eudoxus Sans";
  font-weight: 700;
  font-size: 18px;
  color: #151616;
}

.greenBletIcon {
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 8px;
  object-fit: cover;
}

.greenbeltRow {
  width: 244px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.checkFilledIcon {
  height: 16px;
  width: 16px;
  position: relative;
}

.greenbeltToggles {
  position: relative;
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f5f4;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 0 0;
  gap: 12px;
}

.greenbeltToggles .indexStyle {
  position: absolute;
  background: #1f443a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  color: white;
  font-size: 9px;
  top: 3px;
  left: 3px;
}

.greenbeltToggles .layerStyle{
  position: absolute;
  padding: 4px;
  background: #1f443a;
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 25px; */
  border-radius: 6px;
  color: white;
  left: 3px;
  bottom: 3px;
  font-size: 9px;
  z-index: 9999;
}

.greenbeltToggles input[type="checkbox"] {
  accent-color: #15614D; /* Change this to your desired color */
  /* margin-right: 10px; */
}

.areaOfOutstanding {
  /* height: 32px; */
  flex: 1;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Eudoxus Sans";
  color: #556561;
  text-align: left;
  display: inline-block;
}

.greenbeltOptions {
  align-self: stretch;
  gap: 8px;
}

.areaDropdown,
.greenbeltOptions,
.layersidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.areaDropdown {
  align-self: stretch;
  padding: 0 1px 0 0;
  gap: 16px;
  height: calc(100vh - 237px);  
  width: 310px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.layersidebar {
  margin: 0;
  width: 340px;
  background-color: #fff;
  border-right: 1px solid #f6f8f7;
  box-sizing: border-box;
  overflow: hidden;
  padding: 16px 15px 0px;
  gap: 15.8px;
  line-height: normal;
  letter-spacing: normal;
}