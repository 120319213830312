.shareProjectContainer {
    width: 600px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .para {
        margin: 0;
        margin-top: 3px;
        font-family: 'Eudoxus Sans';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: #787B82;

        span {
            color: #1E232E;
        }
    }

    .selectContainer {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px
    }

    .line {
        margin-top: 30px;
        width: 600px;
        height: 1px;
        background: #DCDFE5;
    }

    .permissionContainer {
        // margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .accessContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .permission_detail {
                margin: 0;
                color: #787B82;
                font-family: "Eudoxus Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .setPassword {
            color: #1F443A;
            font-family: "Eudoxus Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    .passwordContainer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        button {
            font-size: 14px;
            font-family: "Eudoxus Sans";
            font-weight: 500;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
            text-decoration: none;
            height: 40px;
            background: #1F443A;
            color: #fff;
            border-radius: 12px;
            padding: 0px 32px;
            border: 1px solid #1F443A;
        }
    }
}
