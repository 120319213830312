.map-overlay {
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: relative;
  left: 5px;
  top: 100px;
  background: whitesmoke;
  width: 70px;
  border: ridge;
}

.togglerButton {
  position: absolute;
  left: 25px;
  top: -15px;
  pointer-events: auto !important;
  cursor: pointer !important;
  margin: 10px !important;
}

.largeProjecttoggler {
  position: absolute;
  left: 205px;
  top: -15px;
  pointer-events: auto !important;
  cursor: pointer !important;
  margin: 10px !important;
}
.largeProjecttoggler label {
  width: 178px;
}

.baseButton {
  position: absolute;
  left: 420px;
  top: 0px;
  pointer-events: auto !important;
  cursor: pointer !important;
  margin: 10px !important;
  /* background: #fff;
  padding: 8px;
  border: 0px;
  border-radius: 8px;
  width: 100px; */
}

.baseButton button {
  font-weight: 500 !important;
}

.baseModal {
  position: absolute;
  display: block;
  top: 45px;
  left: 430px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  padding: 8px;
}

.baseModal .modalContent label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  height: 20px;
  font-family: 'Eudoxus Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: '#151616'
}

.npsButton {
  position: absolute;
  left: 820px;
  top: 0px;
  pointer-events: auto !important;
  cursor: pointer !important;
  margin: 10px !important;
  background: #fff;
  padding: 8px;
  border: 0px;
  border-radius: 5px;
  font-family: 'Eudoxus Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: '#151616'
}

.npsButton2 {
  position: absolute;
  left: 820px;
  top: 0px;
  pointer-events: auto !important;
  cursor: pointer !important;
  margin: 10px !important;
  background: #fff;
  padding: 8px;
  border: 0px;
  border-radius: 5px;
  font-family: 'Eudoxus Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #666
}

.npsModalWarning {
  position: absolute;
  display: block;
  top: 32.5px;
  left: 670px;
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(227, 83, 5);
  border-radius: 5px;
  background-color: #de8005;
}

.npsModal {
  position: absolute;
  display: block;
  top: 45px;
  left: 670px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  padding: 8px;
}

.npsModal .modalContent label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  font-family: 'Eudoxus Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: '#151616'
}
.npsModal .modalContent input[type="checkbox"] {
  accent-color: #15614D; /* Change this to your desired color */
  margin-right: 10px;
}

.reinforceModal {
  position: absolute;
  display: block;
  top: 45px;
  left: 560px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  padding: 8px;
}

.reinforceModal .modalContent label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  font-family: 'Eudoxus Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: '#151616'
}
.reinforceModal .modalContent input[type="checkbox"] {
  accent-color: #15614D; /* Change this to your desired color */
  margin-right: 10px;
}

.userMarker {
  height: 100px;
  width: 100px;
  background-image: url(../../Images/realestate_search.png);
  background-size: contain;
  cursor: pointer;
}

.selectedSubMarker {
  height: 100px;
  width: 100px;
  background-image: url(../../Images/Location2-01-01.png);
  background-size: contain;
  cursor: pointer;
}

.planningMarker {
  background-image: url(../../Images/doc-icon.png);
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 8px rgba(0, 255, 0, 0.6), 0px 0px 2px 12px rgba(0, 255, 0, 0.4), 0px 0px 2px 20px rgba(0, 255, 0, 0.3); 
  cursor: pointer;
}

.redMarker {
  background-image: url(../../Images/transformer-red.png);
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 8px rgba(255, 0, 0, 0.6), 0px 0px 2px 12px rgba(255, 0, 0, 0.4), 0px 0px 2px 20px rgba(255, 0, 0, 0.3);
  cursor: pointer;
}

.greenMarker {
  background-image: url(../../Images/transformer-green.png);
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 8px rgba(0, 255, 0, 0.6), 0px 0px 2px 12px rgba(0, 255, 0, 0.4), 0px 0px 2px 20px rgba(0, 255, 0, 0.3);
  cursor: pointer;
}

.greenCircle {
  width: 10px;
  height: 10px;
  background: rgb(0, 199, 0);
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px rgba(0, 255, 0, 0.6), 0px 0px 2px 3px rgba(0, 255, 0, 0.4), 0px 0px 2px 4px rgba(0, 255, 0, 0.3);
  cursor: pointer;
}

.lineMarker {
  background-color: green;
  color: white;
  width: 80px;
  text-align: center;
  border-radius: 5px;
  z-index: 400;
  position: relative;
  top: -5px;
  left: -15px;
}

.popUp {
  background-color: green;
  color: white;
  width: 80px;
  text-align: center;
  border-radius: 5px;
  position: relative;
}

.maplibregl-popup-content {
  background: #ececec !important;
  border-radius: 12px !important;
  box-shadow: 0 3px 14px #0006 !important;
  color: #333 ! important;
  width: max-content !important;
}

.maplibregl-popup-tip {
  border-top-color: #ececec !important;
}

/*layerSwitcherControl*/
.maplibregl-ctrl-basemaps {
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  bottom: 15px;
  position: relative;
}

.maplibregl-ctrl-basemaps.reverse {
  flex-direction: row-reverse;
}

.maplibregl-ctrl-basemaps.column {
  flex-direction: column;
}

.maplibregl-ctrl-basemaps.column.reverse {
  flex-direction: column-reverse;
}

.maplibregl-ctrl-basemaps .basemap {
  width: 64px;
  height: 64px;
  margin: 2px;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  cursor: pointer;
}

.maplibregl-ctrl-basemaps .basemap.active {
  border-color: orange;
  box-shadow: 2px 2px 4px #000;
}

.maplibregl-ctrl-basemaps.closed .basemap {
  display: none;
}

.maplibregl-ctrl-basemaps.closed .basemap.active {
  display: block;
  border: 2px solid #ccc;
}

.legend-container {
  min-width: 230px;
  position: absolute;
  height: 260px;
  bottom: 30px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;

}

.openLegend-container {
  position: absolute;
  bottom: 30px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

}

.renewable-container {
  margin-top: 15px;
  height: 240px;
  overflow-y: auto;
}

.sidebar {
  display: none;
  background-color: #1F443A;
  color: #fff;
  padding: 0px 6px;
  font-family: monospace;
  z-index: 999;
  position: absolute;
  top: 35px;
  left: 42px;
  margin: 12px;
  border-radius: 10px;
}

.arrow-close-legend {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
  margin: 5px
}

.legend-item {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-color {
  width: 38px;
  height: 13px;
  display: inline-block;
  margin-right: 5px;
}

.maplibregl-ctrl-top-right {
  position: absolute !important;
  top: 0 !important;
  left: -159px !important;
  z-index: "9999999999" !important;
}

.legend-heading {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold
}

.legend-main {
  margin: 15px;
}