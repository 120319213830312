.signup {
    display: flex;
    // align-items: center;

    &__container {
        position: relative;
        width: 50%;
        height: 100vh;
        padding: 40px;
        box-sizing: border-box;
        background: white;

        .successContainer {
            width: 400px;
            padding-top: 50px;
            text-align: center;
        }

        form {
            width: 400px;

            input {
                width: 100%;
                height: 42px;
                border-radius: 12px;
                border: 1px solid #C5CAD4;
                font-family: "Eudoxus Sans";
                padding-left: 10px;
                box-sizing: border-box;
                margin-top: 20px;
            }

            .formikError {
                color: red;
                font-size: 14px;
                // margin-top: 0.5rem;
            }

            button {
                height: 40px;
                width: 100%;
                margin-top: 20px;
                background: rgb(31, 68, 58);
                color: rgb(255, 255, 255);
                border-radius: 12px;
                border: 1px solid rgb(31, 68, 58);
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                outline: none;
                border: 0;
                padding: 14px;
                box-sizing: border-box;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:hover {
                    background: rgb(50, 88, 78); 
                  }
            }
        }

        &__heading {
            margin-top: 45px;
            margin-bottom: 0;
            font-family: 'Eudoxus Sans';
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1E232E;
        }

        &__para {
            margin: 0;
            margin-bottom: 25px;
            font-family: 'Eudoxus Sans';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #515670;

            a {
                font-weight: 700;
                color: #1F443A;
                &:hover{
                font-weight: 800;
                color: #234b41;
                }
            }
        }

        &__link {
            margin-top: 20px;
            color: #1F443A;
            font-family: 'Eudoxus Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            text-decoration-line: underline;
            a {
                font-weight: 700;
                color: #1F443A;
                &:hover{
                    font-weight: 800;
                    color: #234b41;
                    }
            }
        }

        &__spanLink {
            color: #1F443A;
            font-family: 'Eudoxus Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-decoration-line: underline;

            span {
                font-weight: 700;
                cursor: pointer;
            }
        }

        &__footer {
            position: absolute;
            box-sizing: border-box;
            width: 80%;
            left: 40px;
            right: 80px;
            bottom: 10px;

            &__para {
                margin: 0;
                font-family: Eudoxus Sans;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                color: #73778B;
            }

            &__links {
                p {
                    margin: 0;
                    margin-top: 8px;
                    font-family: 'Eudoxus Sans';
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #73778B;
                    
                    a {
                        cursor: pointer;
                        margin-left: 10px;
                        font-family: 'Eudoxus Sans';
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        color: #1E232E;
                    }
                }
            }
        }
    }


    &__rightSide {
        position: relative;
        width: 50%;
        height: 100vh;
        background: #1F443A;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 500px;
            height: 500px;
          
        }

        &__para {
            position: absolute;
            left: 40px;
            bottom: 10px;
            font-family: Eudoxus Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            color: #fff;
        }
    }
}