body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Eudoxus Sans',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: lightgray; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Eudoxus Sans',
    monospace;
}

.leaflet-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.css-17fvjhf-MuiBadge-badge {
  background: #1F443A !important;
  color: #14D92B !important;
  border-radius: 4px !important;
  font-size: 9px !important;
  font-weight: bold !important;
  height: 16px !important;
  width: 16px !important;
  padding-top: 2.5px !important;
}

.css-av538e-MuiButtonBase-root-MuiIconButton-root:hover {

  border-radius: 0;
  overflow: hidden;
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-19r6kue-MuiContainer-root,
.css-1ekb41w {
  max-width: 4016px !important
}

.go695645437 {
  box-shadow: none !important;
}

.go3046353551 {
  overflow: hidden !important;
}

.meetings-iframe-container iframe {
  height: 690px !important;
}

.css-av538e-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.css-1p5nen7-MuiButtonBase-root-MuiButton-root:hover {
  text-decoration: none;
  background-color: transparent !important;
  border: 0px solid #1976d2;
}

:focus-visible {
  outline: none !important;
}

/* .css-1p5nen7-MuiButtonBase-root-MuiButton-root:active {
  box-shadow: none !important;
} */

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: transparent !important;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
  width: 0 !important;
  height: 0 !important;
}

.grid-station-popup .leaflet-popup-content {
  margin-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
}

.grid-station-popup .leaflet-popup-content-wrapper {
  /* margin-bottom: 20px; */
}

.grid-station-popup .leaflet-popup-tip-container {
  margin-bottom: 20px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: rgb(31, 68, 58) !important;
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0px !important;
  right: 0;
  border: none;
  text-align: center;
  /* width: 50px !important;
  height: 50px !important; */
  /* font: 24px/24px Tahoma, Verdana, sans-serif !important; */
  color: #757575;
  text-decoration: none;
  background: transparent;

}

.leaflet-popup-content {
  /* width: 400px !important; */
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #ececec !important;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  /* width: 450px; */
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.css-1a5icme.Mui-checked {
  color: #1F443A !important;
}

.css-1rwka5b {
  margin-left: 0px !important;
  margin-right: 8px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-uhb5lp {
  border-radius: 24px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-4ff9q7.Mui-active,
.css-4ff9q7.Mui-completed {
  color: #1F443A !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed, 
.css-1hv8oq8-MuiStepLabel-label.Mui-active, 
.css-1vyamtt-MuiStepLabel-labelContainer,
.css-1hv8oq8-MuiStepLabel-label,
.css-2fdkz6.Mui-completed,
.css-2fdkz6.Mui-active {
  color: #6C6E6C !important;
  font-family: 'Eudoxus Sans' !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  padding-right: 10px !important;
}
.Toastify__toast{
  border-radius: 15px !important;
}

.css-13cymwt-control {
  height: 42px !important;
  border-radius: 12px !important;
}

.css-t3ipsp-control {
  height: 42px !important;
  border-radius: 12px !important;
  border-color: #1F443A !important;
  box-shadow: none!important;
}

.css-t3ipsp-control:hover {
  border-color: #1F443A !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #000 !important;
}