// _colors.scss
$slider_color: #1F443A;
.container{
  margin: 0 0 0 30px; 
  // margin-bottom: -10px;
  img{
    margin-bottom: -15px;
    margin-top: -12px;
  }
  p{
    margin-bottom: 50px;
    margin-left: -30px;
    font-family: Eudoxus sans;
    font-weight: bold;
    color: #1F443A;
    font-size: 16px;
       
  }
  .slider{
    margin-left: -20px;
  }
  .range_values{
     display: flex;
     justify-content: space-between;
     margin: -15px 10px -30px 0px;

  }
}