.main1_container {
  position: absolute;
  display: flex;
  // width: 420px;
  top: 65px;

  .icon_container {
    width: 80px;
    height: calc(100vh - 65px);
    background: #edf0ef;
    // border-right: 1px solid #DCDFE5;
    border-right: 1px solid rgba(31, 68, 58, 0.16);
    box-sizing: border-box;
    border-top: 0px;

    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 1px solid #dcdfe5;
      border-left: 4px solid #edf0ef;
    }

    .active {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 1px solid #dcdfe5;
      border-left: 4px solid #1f443a;
    }
  }

  .left_sidebar_container {
    position: absolute;
    width: 340px;
    height: calc(100vh - 65px);
    // top: 65px;
    left: 80px;
    // minHeight: "43em",
    box-sizing: border-box;
    background: white;
    // z-index: 1;
    // overflow-y: scroll;
    overflow-y: auto;
    // padding-bottom: 30px;

    .search_bar {
      height: 40px;
      transform: scale(0.7);

      h1 {
        text-transform: uppercase;
        font-family: "Eudoxus Sans";
        font-weight: 500;
        font-size: 12px;
        margin: 10px 100px 8px 4px;
        color: #787b82;
      }
    }

    .quick_selectors {
      position: relative;
      top: 10px;

      .quick_selectors_checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-right: 9px;
        margin-bottom: 20px;
      }
    }
    .radio_selector {
      margin-top: 30px;
    }

    .assets_container {
      margin-left: 0px;

      .assets_buttons {
        // margin-left: -10px;
        margin-right: 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    .filer_buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin: 20px;
    }
  }
}

.close_leftbar {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.range_slider {
  position: relative;
  top: 20px;
  bottom: 20px;

  .power_select {
    position: absolute;
    top: 0;
    right: -30px;
  }
}

// .css-druciv-MuiDrawer-docked .MuiDrawer-paper {
//     height: calc(100vh - 65px);
// }
