.main1_container {
    position: absolute;
    display: flex;
    width: 100%;
    top: 65px;

    .icon_container {
        width: 80px;
        height: calc(100vh - 65px);
        background: #EDF0EF;
        // border-right: 1px solid #DCDFE5;
        border-right: 1px solid  rgba(31, 68, 58, 0.16);;
        box-sizing: border-box;
        border-top: 0px;

        .icons {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            cursor: pointer;
            box-sizing: border-box;
            border-bottom: 1px solid #DCDFE5;
            border-left: 4px solid #EDF0EF;
        }

        .active {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            cursor: pointer;
            box-sizing: border-box;
            border-bottom: 1px solid #DCDFE5;
            border-left: 4px solid #1F443A;
        }
    }

    .projectContainer {
        width: calc(100% - 80px);
        padding: 24px;

        &__heading {
            color: #1E232E;
            font-family: 'Eudoxus Sans';
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 30px;
        }
    }
}