.saveContainer {
    width: 500px;
    padding: 20px;
    z-index: 1111111;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .buttonContainer {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}