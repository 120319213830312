#mapBar {
    // margin-top: 20px;
    display: flex;
    height: 65px;
    background-color: #1F443A;
    // justify-content: center;
    align-items: center;

    .item {
        height: 70%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // font-family: "Eudoxus Sans";
        // font-size: 16px;
        // line-height: 19px;
        // cursor: pointer;
        // box-sizing: border-box;
        // padding: 10px;
        // border-bottom: 4px solid #349879;

        a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;
            // border-bottom: 4px solid #fff;

            color: #659A7F;
            font-size: 16px;
            font-weight: 500;
            font-family: "Eudoxus Sans";
            line-height: 19px;
            cursor: pointer;
            text-decoration: none;
            transition: transform 0.2s, color 0.2s;
            &:hover{
                color: #82a593;
                transform: scale(1.1);
            }
        }

        .activeLink {
            color: #0AED24;
            border-radius: 8px;
            background: rgba(10, 237, 36, 0.10);
            font-weight: bold;
            // border-bottom: 4px solid #1F443A;
        }
    }
}