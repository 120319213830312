.buttonC {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-family: 'Eudoxus Sans';
    font-weight: 500;
    outline: none;
    border: 0;
    padding: 14px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    background-color: #c9ddd8;

    div {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 8px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #1F443A;

        color: #FFF;

        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Eudoxus Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.2px;
    }
}