.container {
  display: flex;
  align-items: center;
  gap: 47px;
  width: 200px;

  p,
  h3 {
    flex: 0 0 50%; /* Equal width for both elements */
    width: 100%; /* Ensure they take up the full width of their container */
    // overflow: hidden; /* Prevent text overflow */
    white-space: nowrap; /* Prevent line breaks */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
    margin: 0;
    padding: 0;
  }
  p {
    color: #787b82;
    font-family: "Eudoxus Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    width: 50%;
}
h3 {
    color: #1e232e;
    font-family: "Eudoxus Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    width: 50%;
  }
}


