.profileContainer {
    width: 500px;
    min-height: 400px;
    padding: 20px;

    .headingContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin: 0;
            font-family: "Eudoxus Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #1e232e;
        }
    }

    .imageContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 40px 0;

        img {
            width: 95px;
            height: 95px;
            border-radius: 50%; 
            object-fit: cover;
            object-position: center;
        }

        p {
            font-family: "Eudoxus Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #787b82;
        }
    }

    .para {
        margin: 24px 0;
        margin-top: 24px;
        font-family: "Eudoxus Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: #787b82;
    }

    .buttonContainer {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}

.conststyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 10px;
    border-radius: 10px;
}
