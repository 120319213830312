/* Add this CSS for infinite rotation */
.spinner {
    animation: rotate 1.5s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  