.mainContainer {
  padding: 16px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 101px;
    padding: 25px, 16px;
    margin-bottom: 20px;

    h1 {
      font-family: Eudoxus Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;

      font-family: Eudoxus Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;
      margin: 0;
    }

    p {
      font-family: Eudoxus Sans;
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      padding: 8px;
      cursor: pointer;
    }
  }

  .divider {
    height: 1px;
    background: #e2e2e2;
    width: 100%;
  }

  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .messageCount {
      display: flex;
      gap: 7px;

      .unreadCount {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 5px 8px;
        // background-color: #edf0ef;
        border-radius: 6px;
        // width: 73px;
        margin-top: 4px;
      }

      .allCount {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 5px 8px;
        border-radius: 6px;
        // width: 73px;
        margin-top: 4px;
      }
    }
  }

  .notification {
    display: flex;
    flex-direction: column;

    // border-bottom: 1px solid #dcdfe5;
    .offerbuttons {
      display: flex;
      gap: 10px;
      padding-bottom: 20px;
      padding-left: 54px;
    }
  }

  .profileContainer {
    display: flex;

    gap: 12px;
    margin-top: 19px;
    cursor: pointer;

    .profile {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // gap: 106px;
      width: 266px;

      h1 {
        font-family: Eudoxus Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: #1e232e;
        margin: 0px;
        width: 235px;
      }

      p {
        font-family: Eudoxus Sans;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.20000000298023224px;
        text-align: right;
        color: #1e232e;
        margin: 0px;
      }
    }

    .message {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 266px;
      gap: 20px;
      margin-bottom: 15px;

      h1 {
        font-family: Eudoxus Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: #6c6e6c;
        margin: 0px;
      }

      p {
        font-family: Eudoxus Sans;
        font-size: 10px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;

        color: #6c6e6c;
        margin: 0px;
      }
    }
  }
}