.conatiner {
    display: flex !important;
    justify-content: space-between;
    height: 25px;
}
.no_active_layer{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.indexStyle{
    position: absolute;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    color: white;
}
.closeStyle{
    position: absolute;
    background: red;
    display: flex;
    right: 0px;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    color: white;
    :hover {
        color: gray;
        cursor: default;
    }
}
.layerStyle{
    position: absolute;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 25px;
    border-radius: 6px;
    color: white;
    left: 30%;
    top: 3px;
    font-size: 10px;
    z-index: 9999;
}